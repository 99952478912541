<template>
    <div>
        <el-search :search="search" @on-search="params => flush(1, pag.pageSize, ...params)"></el-search>
        <a-table
                :dataSource="resData"
                :columns="columns"
                :row-selection="rowSelection"
                :loading="loading"
                rowKey="id"
                :pagination="false"
                :bordered="bordered"
                :scroll="scroll">
            <template #action="{ record }">
                <div class="_action">
                    <template v-for="(item, index) in actions">
                        <a-popconfirm v-if="item.event === 'del'"
                                      placement="topRight"
                                      title="确定删除吗?"
                                      @confirm="onDelete(item.event, record.id)"
                                      cancelText="取消"
                                      okText="确认">
                            <a>{{item.name}}</a>
                        </a-popconfirm>
                        <a v-else-if="item.event" @click="emitEvent(item.event, record)" :key="item.event">
                            <template v-if="item.key">{{item.name[record[item.key]]}}</template>
                            <template v-else>{{item.name}}</template>
                        </a>
                        <a-dropdown v-if="item.actions && item.actions.length > 0">
                            <template #overlay>
                                <a-menu @click="event => handleMenuClick(event, record)">
                                    <template v-for="(it, ind) in item.actions">
                                        <a-popconfirm v-if="it.event === 'del'"
                                                      placement="topRight"
                                                      title="确定删除吗?"
                                                      @confirm="onDelete(it.event, record.id)"
                                                      cancelText="取消"
                                                      okText="确认">
                                            <a-menu-item :key="it.event">{{it.name}}</a-menu-item>
                                        </a-popconfirm>
                                        <a-menu-item v-else :key="it.event">
                                            <template v-if="it.key">{{it.name[record[it.key]]}}</template>
                                            <template v-else>{{it.name}}</template>
                                        </a-menu-item>
                                    </template>
                                </a-menu>
                            </template>
                            <a>{{item.name}}<DownOutlined /></a>
                        </a-dropdown>
                    </template>
                </div>
            </template>
        </a-table>
        <div class="pagination">
            <a-pagination
                    size="small"
                    show-quick-jumper
                    show-less-items
                    showSizeChanger
                    :total="pag.total"
                    :current="pag.pageIndex"
                    :page-size="pag.pageSize"
                    :showTotal="(total, range) => `共${total}条`"
                    :pageSizeOptions="pageSizeOptions"
                    @change="(pageIndex, pageSize) => flush(pageIndex, pageSize)"
                    @showSizeChange="(pageIndex, pageSize) => flush(pageIndex, pageSize)"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref, reactive, toRaw, toRefs, unref, computed, onMounted } from 'vue';
    import { DownOutlined } from '@ant-design/icons-vue';
    import {SetupContext} from "@vue/runtime-core";
    import Table from "@/request/Table";
    import {message} from "ant-design-vue";
    
    export default defineComponent({
        name: 'el-table',
        components: {
            DownOutlined,
        },
        props: {
            manual: {
                type: Boolean,
                default: false
            },
            search: {
                type: Array,
                default: []
            },
            columns: Array,
            path: String,
            actions: Array,
            params: {
                type: Object,
                default: {}
            },
            bordered: {
                type: Boolean,
                default: true
            },
            scroll: {
                type: Object,
                default: {
                    x: true
                }
            },
            pageSizeOptions: {
                type: Array,
                default: ['5', '10', '20', '30', '40', '50', '100', '200']
            },
            rowSelection: {
                type: Object,
                default: null
            }
        },
        setup(props: any, ctx: SetupContext) {
            const { manual, params, columns, path } = toRefs(props);
            Table.path = path.value;
    
            onMounted(() => ctx.emit('on-mounted'));
            
            columns.value.forEach((col: any) => {
                col.align = col.align || 'center';
                col.ellipsis = col.ellipsis || true;
            })
            
            const pag = reactive({
                pageIndex: 1,
                pageSize: params.pageSize || 10,
                total: 0,
                pages: 0
            })
            
            const loading = ref(true);
            const resData = ref();
            
            const flush = (pageIndex?: number, pageSize?: number, searchParams?: any) => {
                loading.value = true;
                pag.pageIndex = pageIndex || pag.pageIndex;
                pag.pageSize = pageSize || pag.pageSize;
                let formData = Object.assign({
                    pageIndex: pag.pageIndex,
                    pageSize: pag.pageSize
                }, params.value);
                if (searchParams) {
                    formData = Object.assign(formData, searchParams);
                }
                Table.formPost(`${Table.path}/${Table.listPath}`, formData).then((body: any) => {
                    loading.value = false;
                    if (body.code === 0) {
                        let data = body.data;
                        resData.value = data.records;
                        pag.pageIndex = data.current;
                        pag.pageSize = data.size;
                        pag.total = data.total;
                        pag.pages = data.size;
                    }
                    emitEvent('flush', body);
                })
            }
            
            if (!manual.value) flush();
            
            const onDelete = (event: string, id: string) => {
                let messageLoading = message.loading('请稍后...', 0);
                Table.deleteById(id).then(() => {
                    setTimeout(messageLoading, 200);
                    flush();
                })
            }
            
            const getById = (id: string) => {
                return new Promise(resolve => {
                    let messageLoading = message.loading('请稍后...', 0);
                    Table.getById(id).then((body: any) => {
                        setTimeout(messageLoading, 200);
                        resolve(body);
                    })
                })
            }
            
            const save = (formData: any) => {
                return new Promise(resolve => {
                    let messageLoading = message.loading('请稍后...', 0);
                    Table.save(formData).then((body: any) => {
                        setTimeout(messageLoading, 200);
                        resolve(body);
                    })
                })
            }
            
            const emitEvent = (event: string, record: any) => {
                ctx.emit(`on-${event}`, record);
            }
            
            const handleMenuClick = (e: any, record: any) => {
                emitEvent(e.key, record);
            };
            
            return {
                columns, resData, pag, loading,
                emitEvent,
                onDelete,
                handleMenuClick,
                flush,
                getById,
                save
            };
        }
    })

</script>

<style lang="less">
    ._action {
        display: flex;
        justify-content: space-between;
    }
    .ant-table td {
        white-space: nowrap;
        min-width: 40px;
    }
    .pagination {
        margin-top: 5px;
    }
</style>